import { Button, Card, Col, Descriptions, Empty, Form, Image, Input, Row, Select, Spin, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import coinIcon from 'assets/image/coin.svg';
import { DatePickerCol, LayoutNav } from 'components/layout.component';
import {
  PopupOfflineDepositModal,
  PopupOnlineBankingModal,
  PopupPaymayaModal,
  PopupTransferModal,
  PopupWithdrawMethodModal,
  PopupWithdrawModal
} from 'components/wallet.component';
import dayjs from 'dayjs';
import { AGENT_LOG } from 'enum/cashlog';
import { AGENT_RELATION, TRANSFER_TYPE, ENABLE } from 'enum/state';
import useAccount from 'hooks/account.hook';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { $get } from 'services';
import { toFormatNumber as $f, $mobile, enumToOptions, timeL2S, timeS2L, toFormatNumber, verify } from "utils/common";
import { handleTableChange } from 'utils/table';
import { GATEWAY_CODE_WITHDRAW } from "constants/gateway";

// 錢包

const PageMain: React.FC = () => {
  const { data: $s } = useSite();
  const { init, info, mutate: mutateAccount, permissions: $p } = useAccount();
  const [cardSize, setCardSize] = useState<number>(8);
  const { isCashVersion: $sc, isValidating: $scValidating } = useSite();
  const [amountValue, setAmountValue] = useState<number>(0)

  useEffect(() => {
    let col = 0;

    if ($p('0302')) col++
    if ($p('0303')) col++
    if ($p('0304')) col++

    setCardSize($sc ? (24 / col) : 24);

  }, [$sc, init])

  // 轉帳
  // NOTE: 轉帳成功要清空欄位
  const [isOpenTransferModal, setIsOpenTransferModal] = useState(false);
  const [transferForm] = Form.useForm();

  const [agentList, setAgentList] = useState<TableAttributes>({});
  // 上線帳號
  const { data: _agentUpagentInfo } = $get({ url: 'api/agent/upagent/info' });
  // 下線帳號
  const { data: _agentList } = $get({
    url: 'api/report/agent/list',
    params: {
      PageIndex: 1,
      PageSize: 999,
    }
  })

  const [accountType, setAccountType] = useState();
  const updateAccountType = (value: any) => {
    setAccountType(value);
    transferForm.setFieldsValue({
      DownlineAgent: null,
      Amount: '',
      Remark: '',
    });
    switch (value) {
      case AGENT_RELATION.pageTitleChatroom:
        if (info.Level === 0) {
          setAgentList({
            dataSource: [],
            total: 0
          });
        } else {
          setAgentList({
            dataSource: [{
              value: _agentUpagentInfo.Data.Account,
              label: _agentUpagentInfo.Data.Account,
            }],
            total: 1
          });
        }
        break;
      case AGENT_RELATION.subAgent:
        const data = _agentList.Data.map((item: any, i: number) => ({
          value: item.Account,
          label: item.Account,
        }))
        setAgentList({
          dataSource: data,
          total: _agentList.TotalRecord
        })
        break;

      default:
        break;
    }
  }

  // 提款申請
  const [withdrawmethodForm] = Form.useForm();
  const [isOpenAddBankcardModal, setIsOpenAddBankcardModal] = useState(false);
  const [isOpenWithdrawModal, setIsOpenWithdrawModal] = useState(false);
  const [withdrawmethodAgentList, setWithdrawmethodAgentList] = useState([]);
  // 取得提款卡列表
  const { data: _withdrawmethodAgentList, mutate: mutateCardList } = $get({
    url: 'api/withdrawmethod/agent/list',
    params: {
      PageIndex: 1,
      PageSize: 9999,
    }
  })
  useEffect(() => {
    if (_withdrawmethodAgentList?.Data) {

      const data: any = _withdrawmethodAgentList.Data
        .filter((item: any) => (
          (item.GatewayCode === GATEWAY_CODE_WITHDRAW.銀行卡 && item.Status === ENABLE.enabled) ||
          (item.GatewayCode === GATEWAY_CODE_WITHDRAW.虛擬貨幣 && item.MerchantStatus === ENABLE.enabled)
        ))

      setWithdrawmethodAgentList(data);
    } else {
      setWithdrawmethodAgentList([]);
    }
  }, [_withdrawmethodAgentList]);
  // 提款卡資料
  const [bankcard, setBankcard] = useState<any>({});
  const updateBankcard = (value: string) => {
    const bankData = withdrawmethodAgentList.find((item: any) => item.Id === value);
    setBankcard(bankData);
    withdrawmethodForm.setFieldValue('Amount', '');
  }

  // 交易紀錄
  const [date, setDate] = useState([
    dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  ]);
  const [initDate, setInitDate] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState<SearchParams>({
    PageIndex: 1,
    PageSize: 10,
    CreateStartDate: timeL2S(date[0]),
    CreateEndDate: timeL2S(date[1]),
  });
  const [cashlogAgentList, setCashlogAgentList] = useState<TableAttributes>({});
  const { data: _cashlogAgentList, mutate, isValidating } = $get({
    url: 'api/cashlog/agent/list',
    params: searchParams
  })
  useEffect(() => {
    if (_cashlogAgentList && _cashlogAgentList.Data) {
      setCashlogAgentList({
        dataSource: _cashlogAgentList.Data,
        total: _cashlogAgentList.TotalRecord
      });
    } else {
      setCashlogAgentList({
        dataSource: [],
        total: 0
      });
    }
  }, [_cashlogAgentList]);

  const search = (formData: any) => {
    setSearchParams({
      KeyId: formData.KeyId === AGENT_LOG.agentTransferOut ? AGENT_LOG.agentTransferIng : formData.KeyId,
      PageIndex: 1,
      PageSize: searchParams.PageSize,
      CreateStartDate: timeL2S(date[0]),
      CreateEndDate: timeL2S(date[1]),
    })
    mutate();
  }
  const onClear = () => {
    form.resetFields();
    setSearchParams({
      ...searchParams,
      PageIndex: 1,
      PageSize: 10,
    })
    setInitDate(!initDate);
  }

  enum GATEWAY_CODE {
    銀行卡 = 'DEPOSIT_GATE_BANK_OFFLINE',
    虛擬貨幣 = 'DEPOSIT_GATE_CRYPTO_OFFLINE',
  }

  // 存款
  const [currentManufacturer, setCurrentManufacturer] = useState({});
  const [offlineDeposit, setOfflineDeposit] = useState(false);
  const [paymaya, setPaymaya] = useState(false);
  const [onlineBanking, setOnlineBanking] = useState(false);
  const [amount, setAmount] = useState(0);
  const handleRecharge = (record: any) => {
    switch (record.Code) {
      case GATEWAY_CODE.銀行卡:
        setOfflineDeposit(true);
        break;
      case GATEWAY_CODE.虛擬貨幣:
        setOnlineBanking(true);
        break;
      case 'DEPOSIT_GATE_BANK':

        break;
      default:
        setCurrentManufacturer(record);
        setPaymaya(true);
        break;
    }
  }
  // const { data: _gatewayList } = GetGatewayList({
  //   Type: 0,
  //   // IsOffline: 0
  // });
  const { data: _gatewayList } = $get({ url: 'api/gateway/deposit/list' });

  //轉帳金額不能超過錢包餘額規則
  const verifyAmount = (rule: any, value: any, callback: any) => {
    const balance = parseFloat(info.Balance as any);
    if (parseFloat(value) > balance) {
      callback(i18n.t("insufficientBalance"));
    } else {
      callback();
    }
  };

  return (
    <>
      <LayoutNav />
      <Content>
        <Spin spinning={$scValidating}>
          <Row gutter={[18, 18]} className='mt-1'>

            {/* 錢包餘額 */}
            <Col span={24}>
              <Card className="card-shadow">
                <Row gutter={[12, 12]} align={'middle'}>
                  <Col style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <Image src={coinIcon} width={40} height={40} preview={false} />
                    <span className="size-22 color-04 font-w-md mr-3">
                      {i18n.t('walletBalance')}
                    </span>
                  </Col>
                  <Col span={$mobile ? 24 : ''}>
                    <span className="size-22 color-04 font-w-md" style={{ marginLeft: '8px' }}>
                      {$f(info.Balance)}
                    </span>
                  </Col>
                </Row>
              </Card>
            </Col>

            {/* 存款 */}
            {($p('0302') && $sc) &&
              <Col span={$mobile ? 24 : cardSize}>
                <Card className="card-shadow" bodyStyle={{ padding: '16px' }} style={{ height: '100%' }} title={i18n.t('deposit')} >
                  <Table
                    size="middle"
                    loading={false}
                    dataSource={_gatewayList?.Data?.map((item: any) => ({ ...item, key: item.Id }))}
                    columns={[
                      {
                        dataIndex: 'Code',
                        title: i18n.t('rechargeType'),
                        render: (val) => i18n.t(val),
                      },
                      {
                        title: i18n.t('operation'),
                        width: 60,
                        render: (val, record) => (
                          <Button type="link" onClick={() => handleRecharge(record)}>{i18n.t('deposit')}</Button>
                        )
                      },
                    ]}
                    pagination={false}
                  />
                </Card>
              </Col>}

            {/* 轉帳 */}
            {$p('0303') &&
              <Col span={$mobile ? 24 : cardSize}>
                <Card className="card-shadow" bodyStyle={{ padding: '20px 20px' }} style={{ height: '100%' }} title={i18n.t('transfer')} >
                  <Form layout="vertical" form={transferForm} onFinish={() => setIsOpenTransferModal(true)}>
                    <Row gutter={[12, 12]}>
                      {/* 選代理 */}
                      <Col span={24}>
                        <Row align="middle" gutter={[12, 12]}>
                          <Col span={12}>
                            <div>
                              <Form.Item name="agentAccount" label={i18n.t('agentAccount')} rules={[{ required: true }]}>
                                <Select
                                  className="w-full"
                                  placeholder={`${i18n.t('pleaseSelect')}`}
                                  options={
                                    enumToOptions(AGENT_RELATION)
                                      .filter((r: any) => !(info.Level === 0 && r.value === AGENT_RELATION.pageTitleChatroom))
                                  }
                                  onChange={updateAccountType}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <Form.Item name="DownlineAgent" label={i18n.t('account')} rules={[{ required: true }]}>
                              <Select
                                disabled={!accountType}
                                className="w-full"
                                placeholder={`${i18n.t('pleaseSelect')}`}
                                options={agentList.dataSource}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>

                      {$sc ?
                        // 現金版
                        <Col span={24}>
                          <Form.Item name="Amount" label={i18n.t('amount')} rules={[
                            {
                              validator: verifyAmount
                            },
                            ...verify({ point: 4, zero: false, max: 999999999.9999 })
                          ]}>
                            <Input placeholder={`${i18n.t('inputData')}`} />
                          </Form.Item>
                        </Col>
                        : <>
                          {/* 信用版 */}
                          <Col span={12}>
                            <Form.Item name="TransferType" label={i18n.t('amount')} rules={verify({ point: 4 })}>
                              <Select
                                disabled={!accountType}
                                className="w-full"
                                placeholder={`${i18n.t('pleaseSelect')}`}
                                options={
                                  enumToOptions(TRANSFER_TYPE)
                                    .filter((r: any) => !(accountType === AGENT_RELATION.pageTitleChatroom && r.value === TRANSFER_TYPE.deductBalance))
                                }
                                onChange={(value) => {
                                  setAmountValue(value)
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <div style={{ marginTop: '23px' }}>
                              <Form.Item name="Amount" rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                                <Input disabled={!amountValue} placeholder={`${i18n.t('inputData')}`} />
                              </Form.Item>
                            </div>
                          </Col>
                        </>
                      }
                      {/* 備註 */}
                      <Col span={24}>
                        <Form.Item name="Remark" label={i18n.t('explanation')} rules={[{ required: true }]}>
                          <Input.TextArea
                            size="middle"
                            autoSize={{ minRows: 5, maxRows: 5 }}
                            placeholder={`${i18n.t('inputData')}`}
                            maxLength={100}
                            showCount
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24} className="center">
                        <Button onClick={transferForm.submit} style={{ marginTop: '5px' }}>{i18n.t('send')}</Button>
                      </Col>
                    </Row>
                  </Form>
                </Card>
              </Col>}

            {/* 取款 */}
            {($p('0304') && $sc) &&
              <Col span={$mobile ? 24 : cardSize}>
                <Card className="card-shadow" bodyStyle={{ padding: '20px 20px' }} style={{ height: '100%' }} title={
                  <Row align="middle" justify="space-between">
                    <Col className="size-16" style={{ fontWeight: 600 }}>{i18n.t('withdraw')}</Col>
                    <Col>
                      <Button type="primary" onClick={() => setIsOpenAddBankcardModal(true)}>{i18n.t('addPaymentMethod')}</Button>
                    </Col>
                  </Row>
                }>
                  {(withdrawmethodAgentList?.length || 0) > 0 ?
                    <Form layout="vertical" form={withdrawmethodForm} onFinish={() => setIsOpenWithdrawModal(true)}>
                      <Row gutter={[12, 12]}>
                        <Col span={24}>
                          <Form.Item name="WithdrawMethodId" label={i18n.t('paymentMethod')} rules={[{ required: true }]}>
                            <Select
                              className="w-full"
                              placeholder={`${i18n.t('pleaseSelect')}`}
                              options={withdrawmethodAgentList.map((item: any) => (
                                { value: item.Id, label: item.BankName || item.Crypto }
                              ))}
                              onChange={updateBankcard}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Descriptions size="small" layout={$mobile ? 'vertical' : 'horizontal'} column={1} bordered
                            labelStyle={{ fontSize: 12, fontWeight: 600, minWidth: 170, width: 170 }}
                          >
                            {bankcard.BankName ?
                              <Descriptions.Item label={i18n.t('bankName')}>
                                {bankcard.BankName}
                              </Descriptions.Item> : null
                            }
                            {bankcard.BankAccount ?
                              <Descriptions.Item label={i18n.t('bankAccount')}>
                                {bankcard.BankAccount}
                              </Descriptions.Item> : null
                            }
                            {bankcard.Name ?
                              <Descriptions.Item label={i18n.t('bankAccountName')}>
                                {bankcard.Name}
                              </Descriptions.Item> : null
                            }
                            {
                              $s && ($s.Currency === 'VND' || $s.Currency === 'kVND') ? <></>
                                : <>
                                  {bankcard.Branch ?
                                    <Descriptions.Item label={i18n.t('province')}>
                                      {bankcard.Branch}
                                    </Descriptions.Item> : null
                                  }
                                  {bankcard.City ?
                                    <Descriptions.Item label={i18n.t('city')}>
                                      {bankcard.City}
                                    </Descriptions.Item> : null
                                  }
                                  {bankcard.PhonePrefix ?
                                    <Descriptions.Item label={i18n.t('countryCode')}>
                                      {bankcard.PhonePrefix}
                                    </Descriptions.Item> : null
                                  }
                                  {bankcard.PhoneNumber ?
                                    <Descriptions.Item label={i18n.t('phoneNumber')}>
                                      {bankcard.PhoneNumber}
                                    </Descriptions.Item> : null
                                  }
                                </>
                            }
                            {bankcard.Crypto ?
                              <Descriptions.Item label={i18n.t('currency')}>
                                {bankcard.Crypto}
                              </Descriptions.Item> : null
                            }
                            {bankcard.CryptoType ?
                              <Descriptions.Item label={i18n.t('networkType')}>
                                {bankcard.CryptoType}
                              </Descriptions.Item> : null
                            }
                            {bankcard.CryptoAddress ?
                              <Descriptions.Item label={i18n.t('userAddress')}>
                                {bankcard.CryptoAddress}
                              </Descriptions.Item> : null
                            }
                            {bankcard.Photo && JSON.parse(bankcard.Photo).length > 0 ?
                              <Descriptions.Item label={i18n.t('qrCode')}>
                                <Image src={JSON.parse(bankcard.Photo)[0]} width={80} height={80} />
                              </Descriptions.Item> : null
                            }
                          </Descriptions>
                        </Col>
                        <Col span={24}>
                          <Form.Item name="Amount" label={i18n.t('amount')} rules={[
                            {
                              validator: verifyAmount
                            },
                            ...verify({ point: 4, zero: false })
                          ]}>
                            <Input placeholder={`${i18n.t('inputData')}`} onChange={e => setAmount(Number(e.target.value))} />
                          </Form.Item>
                          {/* <div className="color-05 size-10">
                          {`${i18n.t('single')}: ${setting?.Data.LimitMin} - ${setting?.Data.LimitMax}`}
                        </div> */}
                        </Col>
                        {
                          bankcard.Crypto ?
                            <div className="color-05 size-12">
                              {i18n.t('exchangeRate')}: <span className="color-07">≈ {toFormatNumber(Number(amount) / bankcard.ExchangeRate, 2, 'floor')} {bankcard.Crypto}</span>
                            </div> : null
                        }
                        <Col span={24} className="center">
                          <Button type="primary" style={{ marginTop: '5px' }} onClick={withdrawmethodForm.submit}>{i18n.t('submitWithdrawal')}</Button>
                        </Col>
                      </Row>
                    </Form> :
                    <Empty description={i18n.t('noBankCardInformationAvailable')} />
                  }
                </Card>
              </Col>}

            {/* 交易紀錄 */}
            {/* NOTE: 交易紀錄抽出 */}
            <Col span={24}>
              <Form className='form-search' style={{ marginTop: '18px' }} form={form} onFinish={search}>
                <Row gutter={[12, 12]} style={{ maxWidth: '1200px' }}>
                  <Col span={$mobile ? 24 : 4}>
                    <Form.Item name="KeyId">
                      <Select
                        placeholder={i18n.t('selectCategory')}
                        options={[
                          { value: '', label: `${i18n.t('all')}` },
                          ...enumToOptions(AGENT_LOG).filter(item => !(item.value === 1013))
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <DatePickerCol span={$mobile ? 24 : ''}
                    initDate={initDate} date={date} setDate={setDate} textAlign={'right'} />
                  <Col span={$mobile ? 24 : ''} style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={form.submit} style={{ marginRight: '8px' }}>{i18n.t('search')}</Button>
                    <Button onClick={onClear}>{i18n.t('clear')}</Button>
                  </Col>
                </Row>
              </Form>

              <Table
                className='mt-2'
                size="middle"
                scroll={{ x: 400 }}
                loading={isValidating}
                dataSource={cashlogAgentList.dataSource?.map((item: any) => ({ ...item, key: item.Id }))}
                columns={[
                  {
                    dataIndex: 'KeyId',
                    width: 150,
                    fixed: 'left',
                    title: i18n.t('type'),
                    render: (val: any, { Amount }: any) => (
                      val === String(AGENT_LOG.agentTransferIng) && Amount < 0 ? i18n.t(AGENT_LOG[AGENT_LOG.agentTransferOut]) : i18n.t(AGENT_LOG[val as any])
                    )
                  },
                  {
                    dataIndex: 'UpdateDate',
                    width: 180,
                    title: i18n.t('transactionTime'),
                    render: (val) => timeS2L(val)
                  },
                  {
                    dataIndex: 'TargetAccount',
                    width: 180,
                    title: i18n.t('agentMemberAccount'),
                    render: (val, { OperatorAccount }) => val === info.Account ? OperatorAccount : val
                  },
                  {
                    dataIndex: 'BeforeAmount',
                    width: 180,
                    title: i18n.t('previousBalance'),
                    align: 'right',
                    render: (val) => $f(val)
                  },
                  {
                    dataIndex: 'Amount',
                    width: 180,
                    title: i18n.t('transactionAmount'),
                    align: 'right',
                    render: (val) => $f(val)
                  },
                  {
                    dataIndex: 'AfterAmount',
                    width: 180,
                    title: i18n.t('BalanceAfterModification'),
                    align: 'right',
                    render: (val) => $f(val)
                  },
                  {
                    dataIndex: 'Remark',
                    title: i18n.t('comment'),
                    width: 250,
                    render: (val: any) => val === "null" || val === "" ? '-' : val
                  },
                ]}
                pagination={{
                  showSizeChanger: true,
                  showTotal: (total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`,
                  total: cashlogAgentList.total,
                  pageSize: searchParams.PageSize
                }}
                onChange={(p) => handleTableChange(searchParams, setSearchParams, p)}
              />
            </Col>

          </Row>
        </Spin>

        {/* 存款銀行卡 */}
        <PopupOfflineDepositModal isOpen={offlineDeposit} close={() => setOfflineDeposit(false)} />
        {/* 存款虛擬貨幣 */}
        <PopupOnlineBankingModal isOpen={onlineBanking} close={() => setOnlineBanking(false)} />
        {/* 存款三方 */}
        <PopupPaymayaModal isOpen={paymaya} close={() => setPaymaya(false)} currentManufacturer={currentManufacturer} />

        {/* 新增收款方式 */}
        <PopupWithdrawMethodModal isOpen={isOpenAddBankcardModal} close={() => setIsOpenAddBankcardModal(false)}
          mutate={mutateCardList}
        />
        {/* 提款申請 */}
        <PopupWithdrawModal
          isOpen={isOpenWithdrawModal}
          close={() => setIsOpenWithdrawModal(false)}
          form={withdrawmethodForm}
          setBankcard={setBankcard}
        />

        {/* 確認轉帳 */}
        <PopupTransferModal
          isOpen={isOpenTransferModal}
          close={() => setIsOpenTransferModal(false)}
          clear={() => transferForm.resetFields()}
          getFormValues={transferForm.getFieldsValue}
          accountType={accountType}
          mutate={mutateAccount}
        />
      </Content >
    </>
  );
};

export default PageMain;
