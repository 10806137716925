import Axios from "axios";
import { COOKIE } from "constants/cookie";
import useAccount from "hooks/account.hook";
import Cookies from "js-cookie";

enum ENVIRONMENT {
  開發機 = "development",
  測試機 = "test",
  正式機 = "production",
}

// let baseURL = "/api";
// if (process.env.NODE_ENV === ENVIRONMENT.開發機) baseURL = process.env.REACT_APP_API_URL + "/api";
// Axios.defaults.baseURL = baseURL;

export const axios = Axios.create();
export const axiosAuth = Axios.create();
export const axiosToken = Axios.create();
export const axiosWithAuth = Axios.create();
export const axiosAuthWithoutToast = Axios.create();

const useReq = function (config: any) {
  const token = Cookies.get(COOKIE.TOKEN) as any
  const controller = new AbortController();

  // if (!token) controller.abort();

  config.headers = {
    ...config.headers,
    Authorization: token,
  };

  return {
    ...config,
    signal: controller.signal,
  };
};

// request
axios.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    Promise.reject(error);
  }
);

axiosAuth.interceptors.request.use(useReq, function (error) {
  Promise.reject(error);
});

axiosAuthWithoutToast.interceptors.request.use(useReq, function (error) {
  Promise.reject(error);
});

// 回應聖經
// response
const res = function (res: any) {
  // status 2xx
  if (res.headers.rows) {
    res.data.paging = {
      rows: res.headers.rows,
      page: res.headers.page,
      total: res.headers.total,
    };
  }

  // 未驗證代理流程
  if (res.data.Message === 'NOT_VERIFLIED') {
    window.location.replace('/#/verify');
    return
  } 

  switch (res.data.State) {
    case 'Fail':
      break;
    case 'Error':
      // 未登入
      if (res.data.Message === 'UNAUTHORIZATION') {
        window.location.replace('/#/login/UNAUTHORIZATION');
        // window.location.reload();
      }
      // 權限
      if (res.data.Message === 'PERMISSION_DENIED') {
        // window.location.replace('/#/');
        // window.location.reload();
      }
      // 封鎖
      if (res.data.Message === 'ACCOUNT_BLOCKED') {
        window.location.replace('/#/login/ACCOUNT_BLOCKED');
        // window.location.reload();
      }

      // 加reload是為了防SWR cache
      break;
 
    default:
      break;
  }
  
  return res
};

const useErr = function (error: any) {
  const { logout } = useAccount();

  // if (error.response) {

  //   switch (error.response.status) {
  //     // case 401:
  //     //   auth.refreshAccessToken();
  //     // break;
  //     case 403:
  //       logout();
  //       break;
  //     default:
  //       // 錯誤訊息
  //       const message =
  //         RESPONSE_CODE[
  //         error.response.data.errorCode as keyof typeof RESPONSE_CODE
  //         ];

  //       if (message) toast.error(message);
  //       else toast.error(error.response.data.errorCode);

  //       break;
  //   }
  // }
};

axios.interceptors.response.use(res, useErr);
axiosAuth.interceptors.response.use(res, useErr);
axiosWithAuth.interceptors.response.use(res, useErr);
