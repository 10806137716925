import { message } from 'antd';
import axios from 'axios';
import { RESPONSE_CODE } from 'constants/response';
import i18n from 'i18n';
import QueryString from 'qs';
import useSWR from 'swr';
import { axiosAuth } from 'utils/axios';

// API 工具

export const $get = ({
  url = '',
  params = {},
  allow = true,
  total = true,
  showMessage = true,
  auth = true,
  fail = (data: any) => { },
  success = (data: any) => { }
}) => {
  const query = QueryString.stringify(
    params,
    { addQueryPrefix: true, }
  );
  return useSWR(allow ? (url + query) : null, async () => {
    if (auth) {
      return axiosAuth.get(`${url}${query}`)
        .then(res => {
          if (res?.data?.State === 'Success') {
            success(res.data);
            return total ? res.data : res.data.Data;
          }
          if (res?.data?.State === 'Fail') {
            showMessage && message.error(RESPONSE_CODE[res?.data?.Message as keyof typeof RESPONSE_CODE]);
            fail(res.data);
            return {
              ...res.data,
              Data: [],
            }
          }
        }).catch(() => { })
    } else {
      return axios.get(`${url}${query}`).then(res => res.data);
    }
  }, { revalidateOnFocus: false });
}

export const $post = async (
  { url = '', send = {}, success = (data: any) => { }, fail = (data: any) => { }, showMessage = true, resCode = {}, auth = true },
  setLoading = (b: boolean) => { }
) => {
  setLoading(true);
  const response = await (auth ? axiosAuth : axios).post(url, send).then(res => res.data);
  if (response.State === 'Success') {
    success(response);
  } else {
     // 基於RESPONSE_CODE, 如果有指名則同KEY複寫
     const errorCode: any = {
      ...RESPONSE_CODE,
      ...resCode
    }
    const msg = errorCode[response.Message];
    showMessage && message.error(msg || i18n.t('processingFailed'));
    fail(response);
  }
  setLoading(false);
}
